/** @jsx jsx */
import { jsx, Heading } from "theme-ui"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Layout   from "../../../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/components/layout"
import ItemTags from "../../../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/components/item-tags"
import SEO      from "../../../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/components/seo"
import SnsShare from "./sns_share"
import "../styles/post.css"

type PostProps = {
  data: {
    post: {
      slug: string
      title: string
      date: string
      tags?: {
        name: string
        slug: string
      }[]
      description?: string
      body: string
      excerpt: string
      timeToRead?: number
      banner?: {
        childImageSharp: {
          resize: {
            src: string
          }
        }
      }
      writtenWith?: string
    }
  }
}

const px = [`32px`, `16px`, `8px`, `4px`]
const shadow = px.map((v) => `rgba(0, 0, 0, 0) 0px ${v} ${v} 0px`)

const Post = ({ data: { post } }: PostProps) => {
  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.description ? post.description : post.excerpt}
        image={post.banner ? post.banner.childImageSharp.resize.src : undefined}
        pathname={post.slug}
      />
      <time>{post.date}</time>
      <Heading variant="styles.h2" sx={{mt: 0}}>{post.title}</Heading>
      <section sx={{
        my: 5,
        ".gatsby-resp-image-wrapper": {
          my: [4, 4, 5],
          boxShadow: shadow.join(`, `)
        }
      }}>
        <MDXRenderer>{post.body}</MDXRenderer>
      </section>
      {
        // post.writtenWith && (<section class="written-with">この記事は{post.writtenWith}で書きました。</section>)
      }
      {post.tags && (
        <React.Fragment>
          <ItemTags tags={post.tags} />
        </React.Fragment>
      )}
      {/* <SnsShare */}
        {/* postNode={post} */}
      {/* /> */}
    </Layout>
  )
}

export default Post
